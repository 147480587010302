import * as DialogPrimitives from "@radix-ui/react-dialog";
import clsx from "clsx";
import * as React from "react";

import { overlayClasses } from "@/ui/overlay.tsx";

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitives.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitives.Content> & {
    isMobileDialogEnabled?: boolean;
    alignToEnd?: boolean;
  }
>(
  (
    { className, children, isMobileDialogEnabled, alignToEnd = true, ...props },
    ref,
  ) => (
    <DialogPrimitives.DialogPortal>
      <DialogPrimitives.DialogOverlay
        className={clsx(
          overlayClasses,
          alignToEnd &&
            "has-[[data-mobile-dialog=true]]:max-md:place-items-stretch",
          "grid place-items-center md:overflow-y-auto md:py-10 px-1",
          "transition-all",
        )}
        style={{
          backgroundColor: "#000000d1",
        }}
      >
        <DialogPrimitives.Content
          ref={ref}
          data-mobile-dialog={isMobileDialogEnabled}
          className={clsx(
            "relative",
            "z-30",
            "border shadow-md rounded-xl",
            "bg-neutral-25",
            "outline-none",
            "animate-translate-dropdown",
            "!rounded-lg",

            alignToEnd && "data-[mobile-dialog=true]:max-md:max-w-full",
            alignToEnd && "data-[mobile-dialog=true]:max-md:mt-auto",
            alignToEnd && "data-[mobile-dialog=true]:max-md:max-h-[96%]",
            alignToEnd && "data-[mobile-dialog=true]:max-md:rounded-t-md",
            alignToEnd && "data-[mobile-dialog=true]:max-md:rounded-b-none",
            alignToEnd && "data-[mobile-dialog=true]:max-md:h-full",
            alignToEnd && "data-[mobile-dialog=true]:max-md:overflow-hidden",

            className,
          )}
          {...props}
        >
          {children}
        </DialogPrimitives.Content>
      </DialogPrimitives.DialogOverlay>
    </DialogPrimitives.DialogPortal>
  ),
);
DialogContent.displayName = "DialogContent";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitives.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitives.Title ref={ref} className={className} {...props} />
));
DialogTitle.displayName = "DialogTitle";

const Dialog = DialogPrimitives.Root;
const DialogTrigger = DialogPrimitives.Trigger;
const DialogCloseButton = DialogPrimitives.Close;

export { Dialog, DialogCloseButton, DialogContent, DialogTitle, DialogTrigger };

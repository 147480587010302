import { Button } from "@translated/babele-react";
import { clsx } from "clsx";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ConsecutiveIcon } from "@/assets/images/icons.tsx";
import useDarkMode from "@/hooks/useDarkMode.ts";
import useWindowDimensions from "@/hooks/useWindowDimensions.ts";
import { Badge } from "@/ui/badge/Badge.tsx";

export const CallLara = ({
  connectConversation,
}: {
  connectConversation: () => void;
}) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width <= 768, [width]);

  useEffect(() => {
    const attemptReconnect = async () => {
      const reconnect = localStorage.getItem("reconnect");
      if (reconnect === "true") {
        try {
          await connectConversation();
          localStorage.setItem("reconnect", "false");
        } catch (error) {
          console.error("Reconnection attempt failed", error);
        }
      }
    };

    attemptReconnect();
  }, [connectConversation]);

  const isDarkMode = useDarkMode();

  return (
    <>
      <div
        className={clsx(
          "h-full w-full rounded-xs relative",
          "flex flex-col items-center justify-center gap-2",
          "flex items-center justify-center overflow-hidden rounded-md border-2 border-dashed border-transparent",
          "p-2",
          "transition-colors",
          "lg:relative",
          "lg:isolate",
          "lg:before:bg-neutral-200 lg:before:bg-documentsTranslate",
          "lg:before:size-full",
          'lg:before:absolute lg:before:z-[-1] lg:before:bg-topLeftTopRight lg:before:bg-repeat-y lg:before:content-[""]',
          "bg-neutral-200 lg:bg-transparent",
        )}
      >
        <ConsecutiveIcon width={48} height={48} />
        <div
          className={clsx(
            "text-display-sm",
            "text-center",
            "font-regular",
            "text-neutral-1000",
            "mx-4",
          )}
        >
          {t("commons:interpreter_title")} <br />
          {!isMobile && t("commons:interpreter_subtitle")}
        </div>
        <Badge
          variant={{ tone: "fuchsia", mode: "outline" }}
          className={"w-fit px-1.5 py-4 mt-4  text-fuchsia-1000 "}
          style={{
            backgroundColor: isDarkMode ? "#800080" : "rgba(205, 5, 255, 0.06)",
            border: !isDarkMode
              ? "1px solid rgba(201, 17, 255, 0.13)"
              : "1px solid #9A009A",
            borderRadius: "100rem",
          }}
        >
          {t("commons:experimental_version")}
        </Badge>
        <p
          className={clsx(
            "text-md font-regular mx-5",
            "text-center",
            "text-neutral-1000",
          )}
        >
          {t("commons:interpreter_description")}
        </p>
        {!isMobile && (
          <Button
            onClick={connectConversation}
            variant={{
              tone: "primary",
              size: isMobile ? "xl" : "lg",
            }}
            className={clsx(
              "mt-4",
              " sm:w-[20rem] text-md",
              "absolute bottom-8  sm:static sm:bottom-auto ",
            )}
          >
            {t("commons:call_lara")}
          </Button>
        )}
      </div>

      {isMobile && (
        <Button
          onClick={connectConversation}
          variant={{
            tone: "primary",
            size: isMobile ? "xl" : "lg",
          }}
          className={clsx(" w-auto m-4 self-center")}
        >
          {t("commons:call_lara")}
        </Button>
      )}
    </>
  );
};

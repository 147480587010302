import clsx from "clsx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  ConsecutiveIcon,
  DocumentsIllustration,
  TranslateIllustration,
} from "@/assets/images/icons.tsx";
import { localizedPath } from "@/translations/i18n.ts";
import { ActionButton } from "@/ui/ActionButton.tsx";
import {
  CompactIncognitoSelector,
  FullIncognitoSelector,
} from "@/ui/IncognitoSelector.tsx";

export const ConversationHeader = () => {
  const [isOn, setIsOn] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;

  const handleToggle = () => {
    setIsOn(!isOn);
    const htmlElement = document.querySelector("body");
    htmlElement?.setAttribute(
      "data-theme",
      isOn ? "translated-light" : "translated-dark",
    );
  };

  return (
    <div
      className={clsx(
        "grid grid-cols-[1fr_auto] gap-2 p-3 lg:grid-cols-[1fr_auto] lg:p-4",
        "items-center text-neutral-1000 transition-colors",
        "shrink-0",
        "border-b-1 border-neutral-300 lg:border-transparent",
      )}
    >
      <div className="flex items-center">
        <div className="flex gap-1 lg:gap-2">
          <ActionButton
            to={`https://lara.translated.com/${t("main_menu:links:translate")}`}
            illustration={
              <TranslateIllustration className="size-8 lg:size-10" />
            }
            label={t("navigation_menu:translate_text")}
          />

          <ActionButton
            to={`https://lara.translated.com/${t(
              "main_menu:links:translate",
            )}/${t("main_menu:links:translate_documents")}`}
            illustration={
              <DocumentsIllustration className="size-8 lg:size-10" />
            }
            label={t("navigation_menu:translate_documents")}
          />

          <ActionButton
            to={localizedPath[languageCode]}
            illustration={<ConsecutiveIcon className={`size-8 lg:size-10`} />}
            label={t("navigation_menu:interpreter")}
          />
        </div>
      </div>
      <div className="hidden items-center lg:flex">
        <FullIncognitoSelector onValueChange={handleToggle} value={isOn} />
      </div>
      <div className="flex items-center lg:hidden">
        <CompactIncognitoSelector
          className={"flex"}
          onValueChange={handleToggle}
          value={isOn}
        />
      </div>
    </div>
  );
};

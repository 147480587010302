import clsx from "clsx";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { MobileMenu } from "@/features/header/MobileMenu.tsx";
import { ChangeLocaleMenu } from "@/ui/ChangeLocaleMenu.tsx";
import { LaraLogoExtended } from "@/ui/LaraLogoExtended.tsx";
import { TNavLink } from "@/ui/TNavLink.tsx";

import { envVars } from "../../../envVars.ts";

const headerItemCommonStyle = clsx(
  "text-sm font-medium px-4 py-2.5 rounded-sm transition-all",
  "hover:ring-1 hover:ring-neutral-25 hover:ring-opacity-3",
  "focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-neutral-25 focus-visible:ring-opacity-3",
);

export function Header() {
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  const headerRef = useRef<HTMLDivElement>(null);

  const DesktopMenuItems = [
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:translate")}`,
      i18nLabel: "main_menu:translate",
      isActive: true,
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:about")}`,
      i18nLabel: "main_menu:about",
      isActive: false,
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:pricing")}`,
      i18nLabel: "main_menu:pricing",
      isActive: false,
    },
  ] as const;

  return (
    <>
      <header
        ref={headerRef}
        data-theme="translated-light"
        className={clsx(
          "grid grid-cols-[auto_1fr] items-center text-neutral-25 md:grid-cols-[1fr_auto_1fr]",
          "mx-auto w-full",
          "px-3 py-4 md:px-8",
        )}
      >
        <div className="flex items-center gap-1.5">
          <NavLink
            reloadDocument
            to={`${envVars.VITE_LARA_ENDPOINT}/${t(
              "main_menu:links:translate",
            )}`}
            className={"mr-auto flex items-start gap-3 outline-none"}
          >
            <LaraLogoExtended className="h-10 w-[6.75rem]" />
            <span
              className={clsx(
                "px-1.5 py-0.5 text-label-md font-medium opacity-8",
                "rounded-md bg-purple-400/2 ring-1 ring-neutral-700",
              )}
            >
              beta
            </span>
          </NavLink>
        </div>

        <div className="hidden items-center justify-center gap-1 md:flex">
          {DesktopMenuItems.map((item) => (
            <TNavLink
              key={item.to}
              to={item.to}
              className={() =>
                clsx(
                  item.isActive ? "ring-1 ring-neutral-25 ring-opacity-2" : "",
                  headerItemCommonStyle,
                )
              }
            >
              {t(item.i18nLabel)}
            </TNavLink>
          ))}
          <button
            type="button"
            className={clsx(headerItemCommonStyle)}
            onClick={() =>
              window.open(envVars.VITE_LARA_DEVELOPER_LINK, "_blank")
            }
          >
            {t("main_menu:api")}
          </button>
          <button
            type="button"
            className={clsx(headerItemCommonStyle)}
            onClick={() => {
              if (languageCode === "en") {
                window.open(envVars.VITE_LARA_URL_SUPPORT, "_blank");
              } else {
                if ((Window as any).HubSpotConversations?.widget) {
                  (window as any).HubSpotConversations.widget.load();
                  (window as any).HubSpotConversations.widget.open();
                } else {
                  console.error("HubSpot widget is not available.");
                }
              }
            }}
          >
            {t("main_menu:support")}
          </button>
        </div>

        <div className="relative flex items-center justify-end gap-1 md:gap-4">
          <ChangeLocaleMenu />
          <MobileMenu headerRef={headerRef} />
        </div>
      </header>
    </>
  );
}

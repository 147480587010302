import { NavLink, Path } from "react-router-dom";

type TLinkProps = Omit<
  React.ComponentProps<typeof NavLink>,
  "to" | "relative"
> & { to: Path | string };

export const TNavLink = (props: TLinkProps) => {
  const to = props.to;

  return <NavLink {...props} to={to} />;
};

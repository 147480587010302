import * as DialogPrimitives from "@radix-ui/react-dialog";
import { Button, Menu03Icon, XCloseIcon } from "@translated/babele-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { DialogTitle } from "@/features/dialog/Dialog.tsx";
import { TNavLink } from "@/ui/TNavLink.tsx";

import { envVars } from "../../../envVars.ts";

export const MobileMenu = ({
  headerRef,
}: {
  headerRef: React.RefObject<HTMLDivElement>;
}) => {
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const MobileMenuItems = [
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:translate")}`,
      i18nLabel: "main_menu:translate",
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:about")}`,
      i18nLabel: "main_menu:about",
    },
    {
      to: `${envVars.VITE_LARA_ENDPOINT}/${t("main_menu:links:pricing")}`,
      i18nLabel: "main_menu:pricing",
    },
  ] as const;

  useEffect(() => {
    if (location) {
      setIsOpen(false);
    }
  }, [location]);

  const headerHeight = headerRef.current?.getBoundingClientRect().height;

  const TriggerButtonIcon = isOpen ? XCloseIcon : Menu03Icon;

  return (
    <DialogPrimitives.Root open={isOpen} onOpenChange={setIsOpen}>
      <DialogPrimitives.Trigger asChild data-mobile-menu="true">
        <Button
          aria-label="Main menu trigger"
          variant={{ mode: "ghost" }}
          className={clsx(
            "md:hidden transition-all duration-300 !text-neutral-25 hover:!bg-neutral-25/2",
            "bg-neutral-25/2 ",
          )}
        >
          <TriggerButtonIcon />
        </Button>
      </DialogPrimitives.Trigger>
      <DialogPrimitives.Content
        aria-describedby={undefined}
        style={{
          top: headerHeight,
        }}
        className={clsx(
          "bg-laraBase bg-cover",
          "fixed inset-0 transition-opacity duration-300 border-t-1 border-neutral-100/2",
          "outline-none animate-fade-in z-40",
        )}
      >
        <div className="sr-only">
          <DialogTitle className="sr-only">
            {t("mobile_menu_title")}
          </DialogTitle>
        </div>
        <div className="flex flex-col py-12 p-6 gap-6 text-display-md">
          {MobileMenuItems.map((item, index) => (
            <TNavLink
              key={index}
              to={item.to}
              className={({ isActive }) =>
                clsx(isActive ? "text-neutral-100" : "text-neutral-25")
              }
            >
              {t(item.i18nLabel)}
            </TNavLink>
          ))}
          <button
            className="text-left"
            onClick={() =>
              window.open(envVars.VITE_LARA_DEVELOPER_LINK, "_blank")
            }
          >
            {t("main_menu:api")}
          </button>
          <button
            className="text-left"
            onClick={() => {
              if (languageCode === "en") {
                window.open(envVars.VITE_LARA_URL_SUPPORT, "_blank");
              } else {
                setIsOpen(false);
              }
            }}
          >
            {t("main_menu:support")}
          </button>
        </div>
      </DialogPrimitives.Content>
    </DialogPrimitives.Root>
  );
};

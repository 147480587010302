import { createContext, HTMLAttributes, useState } from "react";

export type ControlPanelContextProps = HTMLAttributes<HTMLElement>;

type ControlPanel = {
  connected: boolean;
  disconnect: () => void;
  showChat: boolean;
  showClientBars: boolean;
  showAudioPlayer: boolean;
  currentPlayingAudioId: string | null;
  setCurrentPlayingAudioId: (id: string | null) => void;
  restartOnlyConversation: boolean;
};

const initialState: ControlPanel = {
  showChat: true,
  showClientBars: true,
  showAudioPlayer: true,
  connected: false,
  disconnect: () => ({}),
  currentPlayingAudioId: null,
  setCurrentPlayingAudioId: () => {},
  restartOnlyConversation: false,
};

export const ControlPanel = createContext({
  ...initialState,
  setData: (newdata: Partial<ControlPanel>) => {
    return newdata;
  },
});

const ControlPanelContext = ({ children }: ControlPanelContextProps) => {
  const [state, setState] = useState<ControlPanel>(initialState);

  return (
    <ControlPanel.Provider
      value={{
        ...state,
        //@ts-ignore
        setData: (newState: Partial<ControlPanel>) => {
          setState({ ...state, ...newState });
        },
        setCurrentPlayingAudioId: (id: string | null) => {
          setState({ ...state, currentPlayingAudioId: id });
        },
      }}
    >
      {children}
    </ControlPanel.Provider>
  );
};

export default ControlPanelContext;

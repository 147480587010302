import * as SwitchPrimitives from "@radix-ui/react-switch";
import {
  ActionItem,
  ActionItemSelectedIcon,
  ActionItemSupportText,
  Button,
  CalendarMinus02Icon,
  CheckIcon,
  EyeOffIcon,
  InfoCircleIcon,
  Lock02Icon,
  MinusIcon,
  Select,
  SelectItem,
  SelectPopover,
  SelectTrigger,
  ShieldTickIcon,
  Tooltip,
  TooltipTitle,
  Translate01Icon,
  TrendUp01Icon,
  XCloseIcon,
} from "@translated/babele-react";
import clsx from "clsx";
import React, { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import {
  InvisibleIllustration,
  SalesPerformanceIllustration,
} from "@/assets/images/icons.tsx";
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/features/dialog/Dialog.tsx";

const Modes = {
  incognito: {
    icon: <InvisibleIllustration />,
    i18nTitle: "translation_mode:incognito_title",
    i18nSubtitle: "translation_mode:incognito_subtitle",
    bulletPoints: [
      {
        i18nLabel: "translation_mode:incognito_listitem_1",
        icon: (
          <ShieldTickIcon
            variant={{ size: "sm" }}
            className="text-primary-1000"
          />
        ),
      },
      {
        i18nLabel: "translation_mode:incognito_listitem_2",
        icon: (
          <MinusIcon variant={{ size: "sm" }} className="text-primary-1000" />
        ),
      },
      {
        i18nLabel: "translation_mode:incognito_listitem_3",
        icon: (
          <EyeOffIcon variant={{ size: "sm" }} className="text-primary-1000" />
        ),
      },
      {
        i18nLabel: "translation_mode:incognito_listitem_4",
        icon: (
          <CalendarMinus02Icon
            variant={{ size: "sm" }}
            className="text-primary-1000"
          />
        ),
      },
      {
        i18nLabel: "translation_mode:incognito_listitem_5",
        icon: (
          <Lock02Icon variant={{ size: "sm" }} className="text-primary-1000" />
        ),
      },
    ],
  },
  learning: {
    icon: <SalesPerformanceIllustration />,
    i18nTitle: "translation_mode:learning_title",
    i18nSubtitle: "translation_mode:learning_subtitle",
    bulletPoints: [
      {
        i18nLabel: "translation_mode:learning_listitem_1",
        icon: (
          <ShieldTickIcon
            variant={{ size: "sm" }}
            className="text-primary-1000"
          />
        ),
      },
      {
        i18nLabel: "translation_mode:learning_listitem_2",
        icon: (
          <TrendUp01Icon
            variant={{ size: "sm" }}
            className="text-success-800"
          />
        ),
      },
    ],
  },
};

export const IncognitoSwitch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => {
  return (
    <SwitchPrimitives.Root
      data-theme="translated-light"
      className={clsx(
        "group",
        "inline-flex h-6 md:h-8 w-10 md:w-12 shrink-0 cursor-pointer items-center rounded-lg",
        "border-2 border-neutral-300 transition-colors",
        "bg-neutral-300",
        "checked:bg-primary-800 checked:border-primary-800",
        "focus-visible:outline-none focus-visible:ring-4",
        "focus-visible:ring-primary-500/5",
        "disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
      ref={ref}
    >
      <SwitchPrimitives.Thumb
        className={clsx(
          "grid place-items-center",
          "pointer-events-none h-5 md:h-6 aspect-square rounded-lg",
          "data-[state=checked]:text-primary-1100 data-[state=unchecked]:text-neutral-800 bg-neutral-25 ring-0",
          "text-sm md:text-md",
          "transition-all data-[state=checked]:translate-x-4 md:data-[state=unchecked]:translate-x-1 data-[state=unchecked]:translate-x-0",
          "shadow-md ",
        )}
      >
        {props.checked ? <CheckIcon /> : <XCloseIcon />}
      </SwitchPrimitives.Thumb>
    </SwitchPrimitives.Root>
  );
});
IncognitoSwitch.displayName = SwitchPrimitives.Root.displayName;

/*
Full version
 */
export const FullIncognitoSelector = ({
  value: isIncognito,
  onValueChange,
}: {
  value: boolean;
  onValueChange: (value: boolean) => void;
}) => {
  const { t } = useTranslation("translate");
  const handleIncognitoEnabledChange = (value: boolean) => {
    onValueChange(value);

    const htmlElement = document.documentElement;
    htmlElement.setAttribute(
      "data-theme",
      value ? "translated-dark" : "translated-light",
    );
  };

  return (
    <div
      className={clsx(
        "bg-neutral-25 transition-colors text-neutral-1000 flex items-center",
        "py-2",
        "rounded-md",
        "text-sm font-medium",
      )}
    >
      {/* This id is referenced from external scripts from GTM. DO NOT REMOVE IT */}
      <div className="flex items-center gap-2 px-3" id="incognito-switch">
        <label
          className={clsx("text-neutral-1000 cursor-pointer")}
          htmlFor="incognitoSwitch"
        >
          {t("translation_mode:label")}
        </label>
        <IncognitoSwitch
          id="incognitoSwitch"
          name="incognito"
          checked={isIncognito}
          onCheckedChange={handleIncognitoEnabledChange}
        />
      </div>
      <div className="border-1 h-6 border-neutral-300 transition-colors" />
      <div className="px-2">
        <Select
          value={isIncognito ? "incognito" : "learning"}
          onValueChange={(value) => {
            handleIncognitoEnabledChange(value == "incognito");
          }}
          placement="bottom"
        >
          <Tooltip
            trigger={
              <SelectTrigger
                render={
                  <Button
                    variant={{ mode: "ghost", size: "xs" }}
                    aria-label={t("translation_mode:button_aria_label")}
                    className="!text-neutral-1000"
                  >
                    <InfoCircleIcon />
                  </Button>
                }
              />
            }
          >
            <TooltipTitle>{t("translation_mode:tooltip_text")}</TooltipTitle>
          </Tooltip>
          <SelectPopover className="z-40 !gap-2">
            <div className="py-1.5 px-3 flex items-center gap-2">
              <Translate01Icon className="fill-primary-1100" />
              <span className="text-sm text-neutral-900 font-regular">
                {t("translation_mode:popover_title")}
              </span>
            </div>
            <div className="w-full bg-neutral-300 h-[0.0625rem] my-1" />
            <div className="grid grid-cols-[repeat(2,_14.6875rem)] gap-1">
              <SelectItem
                value="learning"
                rowId="1"
                className="py-4 px-4 !rounded-lg"
              >
                <Mode modeType={"learning"} />
              </SelectItem>
              <SelectItem
                value="incognito"
                rowId="1"
                className="py-4 px-4 !rounded-lg"
              >
                <Mode modeType={"incognito"} />
              </SelectItem>
            </div>
          </SelectPopover>
        </Select>
      </div>
    </div>
  );
};

/*
Compact version
 */
export const CompactIncognitoSelector = ({
  value: isIncognito,
  onValueChange,
  className,
}: {
  value: boolean;
  onValueChange: (value: boolean) => void;
  className?: string;
}) => {
  const { t } = useTranslation("translate");
  const handleIncognitoEnabledChange = (value: boolean) => {
    onValueChange(value);

    const htmlElement = document.documentElement;
    htmlElement.setAttribute(
      "data-theme",
      value ? "translated-dark" : "translated-light",
    );
  };

  return (
    <div className={clsx(className, "items-center grow justify-between")}>
      {/* This id is referenced from external scripts from GTM. DO NOT REMOVE IT */}
      <div className="flex items-center gap-2 pr-2" id="incognito-switch">
        <label
          className={clsx("text-neutral-1000 cursor-pointer text-sm")}
          htmlFor="incognitoSwitchCondensed"
        >
          <span className="inline lg:hidden transition-colors">
            {t("translation_mode:label_mobile")}
          </span>
          <span className="hidden lg:inline transition-colors">
            {t("translation_mode:label")}
          </span>
        </label>
        <IncognitoSwitch
          id="incognitoSwitchCondensed"
          name="incognito"
          checked={isIncognito}
          onCheckedChange={handleIncognitoEnabledChange}
        />
      </div>
      <div className="border-1 h-6 border-neutral-300 transition-colors" />
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant={{ mode: "ghost", size: "md" }}
            aria-label={t("translation_mode:button_aria_label")}
            className="!text-neutral-1000"
          >
            <InfoCircleIcon />
          </Button>
        </DialogTrigger>
        <DialogContent
          className="max-w-[25rem] flex flex-col gap-1 p-2 md:rounded-sm !h-fit !rounded-b-none"
          aria-describedby={undefined}
          data-mobile-dialog
        >
          <DialogTitle className="pt-2 pb-3 mx-auto font-medium">
            {t("translation_mode:popover_title")}
          </DialogTitle>
          <DialogCloseButton
            className="right-2 absolute md:top-1.5 md:right-2"
            asChild
          >
            <Button
              aria-label={t("translation_mode:close_modal_aria_label")}
              variant={{ mode: "ghost", size: "md" }}
              className="!text-neutral-1000"
            >
              <XCloseIcon />
            </Button>
          </DialogCloseButton>
          <div className="grid gap-1">
            <ActionItem
              className={clsx(
                "pointer-events-none px-3 pt-2 pb-3",
                "aria-[selected=false]:border-1 aria-[selected=false]:border-neutral-400",
              )}
              aria-selected={!isIncognito}
              render={
                <Mode modeType={"learning"} compact={false}>
                  {isIncognito && (
                    <DialogCloseButton asChild>
                      <Button
                        variant={{ tone: "primary" }}
                        className="pointer-events-auto"
                        onClick={() => handleIncognitoEnabledChange(false)}
                      >
                        {t("translation_mode:switch_to_learning")}
                      </Button>
                    </DialogCloseButton>
                  )}
                </Mode>
              }
            />
            <ActionItem
              className={clsx(
                "pointer-events-none px-3 pt-2 pb-3",
                "aria-[selected=false]:border-1 aria-[selected=false]:border-neutral-400",
              )}
              aria-selected={isIncognito}
              render={
                <Mode modeType={"incognito"} compact={false}>
                  {!isIncognito && (
                    <DialogCloseButton asChild>
                      <Button
                        variant={{ tone: "primary" }}
                        className="pointer-events-auto"
                        onClick={() => handleIncognitoEnabledChange(true)}
                      >
                        {t("translation_mode:switch_to_incognito")}
                      </Button>
                    </DialogCloseButton>
                  )}
                </Mode>
              }
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function Mode({
  className,
  children,
  compact = true,
  modeType,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  compact?: boolean;
  modeType: "learning" | "incognito";
}) {
  const { t } = useTranslation("translate");
  return (
    <div className={clsx(className, "flex flex-col gap-4")} {...props}>
      <div className={clsx("flex flex-col", !compact && "gap-2 pt-2")}>
        <div className="flex">
          <picture>{Modes[modeType].icon}</picture>
          <ActionItemSelectedIcon className="ml-auto" />
        </div>
        <div>
          <div
            className={clsx(
              "text-md font-medium",
              "group-aria-selected:text-primary-1000 py-1",
            )}
          >
            {t(Modes[modeType].i18nTitle)}
          </div>
          <ActionItemSupportText
            className={clsx(
              "text-sm font-regular",
              "group-aria-selected:text-neutral-1000 ",
            )}
          >
            {t(Modes[modeType].i18nSubtitle)}
          </ActionItemSupportText>
        </div>
      </div>
      <ul className={clsx("grid gap-1 text-neutral-1000 text-sm font-regular")}>
        {Modes[modeType].bulletPoints.map((item, index) => (
          <li className={clsx("flex items-center gap-2")} key={index}>
            {item.icon}
            {t(item.i18nLabel)}
          </li>
        ))}
      </ul>
      {children}
    </div>
  );
}

import { ActionItem, ActionItemText } from "@translated/babele-react";
import { clsx } from "clsx";
import { ReactNode } from "react";
import { Path } from "react-router-dom";

import { TNavLink } from "@/ui/TNavLink.tsx";

export const ActionButton = ({
  to,
  illustration,
  label,
}: {
  to: Path | string;
  illustration: ReactNode;
  label: string;
}) => {
  return (
    <ActionItem
      className="relative flex items-center gap-2 !rounded-sm !p-3 lg:!py-3 lg:!pl-4 lg:!pr-5"
      variant={{ size: "md" }}
      render={({ children, className }) => (
        <TNavLink
          to={to}
          className={clsx(
            className,
            'aria-[current="page"]:bg-primary-200 aria-[current="page"]:text-primary-1000',
            'aria-[current["page"]:hover:text-primary-1000 aria-[current="page"]:hover:bg-primary-100 aria-[current="page"]:focus-visible:bg-primary-200',
          )}
          end
        >
          {children}
        </TNavLink>
      )}
    >
      {illustration}
      <ActionItemText className="font-medium max-lg:sr-only">
        {label}
      </ActionItemText>
    </ActionItem>
  );
};
